import { Grid, Paper, Tab, Tabs } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import Condolences from './condolences'
import Information from './information'


interface TabSectionProps {
  decease: any
}
const TabsSection = ({ decease }: TabSectionProps) => {
  const [tabValue, setTabValue] = React.useState(0)

  if (!decease || Object.entries(decease).length === 0) {
    return <></>
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <>
      <SGridContainer container direction="column" style={{ paddingTop: 32 }}>
        <Grid container direction="row">
          <Grid item md={3} />
          <Grid item xs={12} md={6}>
            <SPaper>
              <STabs
                indicatorColor="primary"
                onChange={handleTabChange}
                textColor="primary"
                value={tabValue}
                variant="fullWidth"
                color={decease.site.color}
                centered
              >
                <Tab label="Información" />
                <Tab label="Condolencias" />
              </STabs>
            </SPaper>
          </Grid>
          <Grid item md={3} />
        </Grid>
        {tabValue === 0 && <Information decease={decease} />}
        {tabValue === 1 && <Condolences decease={decease} />}
      </SGridContainer>
    </>
  )
}

const SGridContainer = styled(Grid)`
  flex: 1;
`

const SPaper = styled(Paper)`
  box-shadow: none;
`

interface STabsProps {
  color: string
}
const STabs = styled(Tabs)<STabsProps>`
  .MuiTab-textColorPrimary.Mui-selected {
    color: ${(props: STabsProps) => props.color};
  }
  .MuiTabs-indicator {
    background-color: ${(props: STabsProps) => props.color};
  }
`

export default TabsSection
