import { Masonry as MasonryVendor } from 'masonic'
import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import Condolence from '~Screens/View/components/condolences/condolence'

interface MasonryProps {
  condolences: any
  decease: any
  setReloadCondolences: any
}
const Masonry = ({
  condolences,
  decease,
  setReloadCondolences,
}: MasonryProps) => {
  const MasonryCard = ({ index, data, width }: any) => {
    return (
      <SCondolenceContainer item key={data.UUID}>
        <Condolence
          condolence={data}
          setReloadCondolences={setReloadCondolences}
          siteUrl={decease.site.url}
        />
      </SCondolenceContainer>
    )
  }

  return (
    <MasonryVendor
      items={condolences}
      render={MasonryCard}
      columnGutter={5}
      columnWidth={400}
      itemKey={(data: any, index) => (data ? data.UUID : '')}
      style={{ marginTop: 24 }}
    />
  )
}

const SCondolenceContainer = styled(Grid)`
  max-width: 600px;
  width: 100%;
`

export default Masonry
