import React, { useContext } from 'react'
import { Button, Menu as MenuMaterialUi, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { deleteSiteCondolence } from '~Services/index'
import ConfirmationDialog from '~Components/ConfirmationDialog'
import LoaderContext from '~Contexts/Loader.context'
import SnackbarContext from '~Contexts/Snackbar.context'

interface MenuProps {
  condolence: any
  setReloadCondolences: any
  siteUrl: string
}
const Menu = ({ condolence, setReloadCondolences, siteUrl }: MenuProps) => {
  const { hideLoader, showLoader } = useContext(LoaderContext)
  const { openSnackbar } = useContext(SnackbarContext)

  const [open, setOpen] = React.useState(false)
  const [
    openConfirmDialogToDelete,
    setOpenConfirmDialogToDelete,
  ] = React.useState(false)

  const anchorRef = React.useRef<HTMLButtonElement>(null)

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleClick = () => {
    setOpen(!open)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const removeThisCondolence = () => {
    showLoader()

    deleteSiteCondolence(siteUrl, condolence.UUID)
      .then((response) => {
        if (response.data.errors.length) {
        } else {
          setReloadCondolences(true)
        }
      })
      .catch((error) => {
        openSnackbar(error.message, 'error')
      })
      .finally(() => {
        setOpenConfirmDialogToDelete(false)
        setOpen(false)
        hideLoader()
        openSnackbar('La condolencia se ha eliminado correctamente', 'success')
      })
  }

  return (
    <>
      <Button
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
        ref={anchorRef}
        type="button"
      >
        <MoreVertIcon />
      </Button>
      <MenuMaterialUi
        anchorEl={anchorRef.current}
        id="menu"
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setOpenConfirmDialogToDelete(true)}>
          Eliminar
        </MenuItem>
      </MenuMaterialUi>

      <ConfirmationDialog
        confirmationButtonTitle="SI, ELIMINAR"
        description="Esta acción es definitiva y no podrá deshacerse."
        handleClose={() => setOpenConfirmDialogToDelete(false)}
        handleConfirm={removeThisCondolence}
        open={openConfirmDialogToDelete}
        title="¿Desea eliminar la condolencia?"
      />
    </>
  )
}

export default Menu
